import moment from 'moment'
import Vue from 'vue'

export const globalAdminId = '100'
export const adminRoleId = '99'
export const managerRoleId = '2'
export const employeeRoleId = '1'
export const cashierRoleId = '3'

export const getTokenData = () => {
  if (localStorage.getItem('token')) {
    const jwtData = localStorage.getItem('token').split('.')[1]
    const decodedJwtJsonData = decodeURIComponent(
      Array.prototype.map
        .call(atob(jwtData), function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    const decodedJwtData = JSON.parse(decodedJwtJsonData)
    return decodedJwtData
  }
  return {
    isInAdminRole: false,
    userId: '',
    roleid: '',
    userName: '',
    country: ''
  }
}

export const displayErrors = function (error) {
  Vue.notify({
    classes: '',
    group: 'notify-group',
    title: '',
    type: 'error text-white rounded',
    text: error
  })
}

export const displayInfo = function (content) {
  Vue.notify({
    classes: '',
    group: 'notify-group',
    title: '',
    position: 'bottom right',
    type: 'success mt-4 text-white rounded',
    text: content
  })
}

export const dateTime = value => {
  if (value) {
    return moment(value).format('DD.MM.YYYY HH:mm')
  }
}

export const dateFormat = value => {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
}

export const getEnumDescription = function (enumList, value) {
  let list = []

  if (value === null) return ''

  if (enumList && enumList.length) {
    list = enumList.filter(item => item.id === value)
  }

  return list.length > 0 ? list[0].description : ''
}

export const roles = {
  undefined: 0,
  user: 1,
  admin: +adminRoleId
}

export const isInRole = function (role) {
  return +this.$store.getters.userRoleId === role
}

export const containsSpecialChars = (string) => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':'\\|,.<>/?~]/
  return specialChars.test(string)
}

export const containsDigits = (string) => {
  return /\d/.test(string)
}

export const generateString = () => {
  return Math.random().toString(36).slice(-8)
}

export const downloadFile = (name, response) => {
  const blob = new Blob([response])
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = name
  link.click()
  URL.revokeObjectURL(link.href)
}

export const parseHowManyTimes = (count) => {
  if (count === 1) {
    return 'jeden raz'
  } else {
    return `${count} razy`
  }
}
